
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPropertyInfo } from "@/models/PropertyInfo";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { ILeadStatus } from "@/models/Status";
import { OWNER_LEAD_STATUS } from "@/constants/OwnerLeadStatus";
import { OwnerLeadStatusCategory } from "@/enums/StatusCategory";
import DateUtils from "@/services/utils/DateUtils";

@Component({
  name: "PropertyInfo",
  components: {
    HousfySvg,
  },
})
export default class PropertyInfo extends Vue {
  @Prop({ type: Object, default: () => {} })
  info!: IPropertyInfo;

  get images() {
    if (this.mq("sm", "md", "lg")) return this.info?.images?.slice(0, 5);
    return [this.info?.images?.[0]];
  }

  get statusData(): ILeadStatus | null {
    const status = this.info.statusCategory;
    if (status)
      return OWNER_LEAD_STATUS[status as keyof typeof OWNER_LEAD_STATUS];

    return null;
  }

  get statusCategory() {
    return OwnerLeadStatusCategory;
  }

  get isRentedInManagement() {
    return (
      this.info.statusCategory === OwnerLeadStatusCategory.RENTED_IN_MANAGEMENT
    );
  }

  get fromDate() {
    const { day, month, year } = DateUtils.formatDate(this.info.rentedDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }
}
