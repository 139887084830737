
import { Component, Vue, Prop } from "vue-property-decorator";
import { IContactPerson } from "@/models/ContactPerson";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import { IHousfyError } from "@/models/HousfyError";
import ContactPersonShimmer from "@/components/ContactPersonShimmer.vue";
import { Avatar } from "housfy-ui-lib";
import RatingStars from "@/components/ui/RatingStars.vue";
import CallButton from "@/components/CallButton.vue";
import MailButton from "@/components/MailButton.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";

@Component({
  name: "ContactPerson",
  components: {
    ContactPersonShimmer,
    Avatar,
    RatingStars,
    CallButton,
    MailButton,
    HousfySvg,
  },
})
export default class ContactPerson extends Vue {
  @Prop({ type: String, required: true })
  propertyUuid!: string;

  @Prop({ type: Boolean, default: false })
  pageIsLoading!: boolean;

  contactPerson: IContactPerson | null = null;

  loading = false;
  maxRating = 4.5;

  async mounted() {
    const contactPersonFromStore = this.$store.getters["contact/contactPerson"];
    try {
      this.loading = true;
      if (!contactPersonFromStore) {
        this.contactPerson = await api
          .contact()
          .retrieveContactPerson(this.propertyUuid);
        this.$store.commit("contact/SET_CONTACT_PERSON", this.contactPerson);
      } else this.contactPerson = contactPersonFromStore;
    } catch (error) {
      ErrorService.handleGenericError(error as IHousfyError);
    } finally {
      this.loading = false;
    }
  }
}
