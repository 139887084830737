
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import { IBackOptions } from "@/models/BackOptions";
import { TabbarIds } from "@/enums/HousfyPage";
import { resolveRoutePath } from "@/router";
import { IPropertyInfo } from "@/models/PropertyInfo";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import { IHousfyError } from "@/models/HousfyError";
import PropertyInfoShimmer from "@/components/PropertyInfoShimmer.vue";
import PropertySpecsShimmer from "@/components/PropertySpecsShimmer.vue";
import PropertySpecs from "@/components/PropertySpecs_REFACTOR_OLD.vue";
import PropertyInfo from "@/components/PropertyInfo_REFACTOR_OLD.vue";
import Layout from "@/components/layout/Layout.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyAnchor } from "housfy-ui-lib";
import ContactPerson from "@/components/ContactPerson_REFACTOR_OLD.vue";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

@Component({
  name: "Property",
  components: {
    HousfyPage,
    PropertyInfoShimmer,
    PropertySpecsShimmer,
    PropertyInfo,
    PropertySpecs,
    Layout,
    HousfySvg,
    HousfyAnchor,
    ContactPerson,
  },
})
export default class Property extends Vue {
  headerTitle = "";
  activeTabId = TabbarIds.ACTIVITY;
  propertyInfo: IPropertyInfo | null = null;
  loading = false;

  propertyUuid = this.$route.params.propertyUuid;

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
      titleMobile: this.headerTitle,
    };
  }

  async mounted() {
    try {
      this.loading = true;
      this.propertyInfo = await api
        .property()
        .retrievePropertyInfo(this.propertyUuid);
      this.headerTitle = this.propertyInfo?.fullAddress ?? "";
    } catch (error) {
      ErrorService.handleGenericError(error as IHousfyError);
    } finally {
      this.loading = false;
    }
  }
}
